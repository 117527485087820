<template>
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>

<script setup>
import zhCN from "ant-design-vue/es/locale/zh_CN";

import dayjs from "dayjs";

import "dayjs/locale/zh-cn";

dayjs.locale("zh-cn");
// import { useRouter } from "vue-router";
// const router = useRouter();
//   const goHome=()=>{
//     router.push('/')
//   }
</script>

<style scoped>
.title_bar{
  width: 100vw;
  height:60px;
  position: sticky;
  top: 0;
  background: #00A0EA;
  z-index: 9999;
}
.title_p{
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color:#fff;
  cursor: pointer;
}
</style>
