<template>
<router-view/>
</template>
<script >
document.body.style.backgroundColor='rgba(255,255,255)'
</script>
<style lang="less">
@import "@/utils/replace.css";
*{margin: 0;
padding: 0;}
</style>
