import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    access_token: null,
    phone:null,
    name:null,
  },
  getters: {
  },
  mutations: {
    getusermsg(state, val) {
      state.name = val.name;
      state.phone = val.phone

    },
    getuserdata(state, val) {
      state.userdata = val.user
      // console.log('usermsg', state.userdata);
    },
    clear(state) {
      state.doctor_id = null
      state.doctor_name = null
      state.access_token = null
      // state.userdata = {}
    },
  },
  actions: {

  },
  modules: {
  },
  plugins: [
    createPersistedState()
  ]
})
