import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import { notification } from "ant-design-vue";
import Layout from '@/views/layout.vue';

const routes = [
  {
    path: '/',
    redirect: '/search',
    component: Layout,
    children:[
      {
        path: '/list',
        name: 'list',        
        component: () => import('@/views/index/list.vue'),
      },
      {
        path: '/patient_detail',
        name: 'patient_detail',        
        component: () => import('@/views/index/patientDetail.vue'),
      },
      {
        path: '/series_detail',
        name: 'series_detail',        
        component: () => import('@/views/index/seriesDetail.vue'),
      },
      {
        path: '/series_detail',
        name: 'series_detail',        
        component: () => import('@/views/index/seriesDetail.vue'),
      },
      {
        path: '/search',
        name: 'search',        
        component: () => import('@/views/index/search.vue'),
      },
    ]
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHashHistory('/template/index/index'),
  routes
})
const needlogin = []
router.beforeEach((to, from, next) => {
  if (needlogin.includes(to.path)) {
    if (store.state.access_token) {
      next()
    } else {
      notification.info({message:'登录状态过期，请您重新登录'})
      next('/search')
    }
  } else {
    next()
  }

})
export default router
